<template>
  <div class="stack-context-menu">
    <template v-if="isSuspended">
      <div class="stack-context-menu__noact standart-text">
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
    <template v-else>
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import renamePool from '@/mixins/renamePool';
import replaceSSL from '@/mixins/replaceSSL';
import changePoolDescription from '@/layouts/Stack/mixins/changePoolDescription';
import Vue from 'vue';
import update from '@/layouts/Stack/mixins/update';
export default {
  name: 'PoolContextMenu',
  mixins: [renamePool, showErrorModal, replaceSSL, changePoolDescription, update],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerPoolId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    balancerId() {
      return this.tariff.loadbalancers[0].id;
    },
    id() {
      return this.tariff.id;
    },
    name() {
      return this.tariff.name;
    },
    listener() {
      return this.$store.state.moduleStack.listeners.find(
        x => x.id === this.tariff.listeners[0].id
      );
    },
    isSsl() {
      return (
        !!this.listener.default_tls_container_ref &&
        !!this.listener.default_tls_container_ref.length
      );
    },
    isEnabledSsl() {
      return this.listener.protocol === 'TERMINATED_HTTPS';
    },
    isSuspended() {
      return this.tariff.provisioning_status.key.toLowerCase().includes('pending_');
    },
    menu() {
      const PoolAdminStatus = this.tariff.admin_state_up !== true ? 'true' : 'false';
      const arr = [];
      arr.push([
        {
          key: 'edit',
          id: this.id,
          handler: 'func',
          action: {
            pool: {
              name: this.tariff.name,
            },
          },
        },
        {
          key: 'descr',
          handler: 'descr',
        },
        {
          key: PoolAdminStatus,
          id: this.id,
          handler: 'state',
          label: !this.tariff.admin_state_up,
          action: {
            admin_state_up: !this.tariff.admin_state_up,
          },
        },
        {
          key: 'delete',
          color: 'del',
          handler: 'delete',
          action: 'delete',
        },
      ]);
      return arr;
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.action && item.handler === 'state') this.askSure(item);
      if (item.action && item.handler === 'delete') this.deletePool(item);
      if (item.action && item.key === 'edit') this.renamePool(this.tariff.name, item);
      if (item.handler === 'descr') this.changePoolDescription(this.tariff);
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let mode = '';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text:
          // action.action === 'detach'
          //   ? 'отвзять IP'
          // : this.$t('modal.sure.action', { msg: this.$t(`actions.${action.label}`) }),
          // :
          this.$t(`description.${action.key}`, { name: this.name }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                // Vue.set(self.footer.confirm.props, 'loading', true);
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;

                this.changeAdminState(action)
                  .then(data => {
                    this.isProcessing = false;
                    mode = data.admin_state_up;
                    res = 'success';
                    this.updateBalancerStatus(this.balancerId).then(() => {
                      setTimeout(async () => {
                        await this.updatePoolStatus(this.tariff.id);
                      }, 1000);
                    });
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      // self.text = this.$t(`modal.sure.success.${mode}`);
                      self.text = this.$t(`modal.sure.${res}`, {
                        msg: this.$t(`modal.sure.${mode}`),
                      });
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    changeAdminState(item) {
      return this.$store.dispatch('moduleStack/updatePool', {
        pool: item.action,
        id: item.id,
        item: 'admin_state_up',
      });
    },
    deletePool() {
      const that = this;
      let self;
      const selfName = 'ServerAction';

      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t('modal.sure.text', {
          msg: this.name,
          pool: this.id,
          // cost: this.$n(
          //     this.cost(`${action.key}_${this.tariff.volume_type}`),
          //     'currency',
          //     this.$i18n.locale
          // ),
        }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
              color: 'error',
            },
            on: {
              click: () => {
                Vue.set(self.footer.confirm.props, 'loading', true);
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;

                this.deleteCurrentPool().then(async data => {
                  if (data === 204) {
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t('modal.sure.delete');
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close'), loading: false },
                          on: {
                            click: async () => {
                              await this.deleteCurrentListener();
                              await this.$store.dispatch(
                                'moduleStack/fetchOpenStackRequest',
                                'pools'
                              );
                              await this.updateBalancerStatus(this.balancerId).then(() => {
                                setTimeout(async () => {
                                  await this.updatePoolStatus(this.tariff.id);
                                }, 1000);
                              });
                              this.$modals.close();
                              this.$router
                                .push({ name: 'Balancer', params: { balancerid: this.balancerId } })
                                .catch(() => {});
                            },
                          },
                        },
                      };
                    }, 2000);
                  } else {
                    this.$modals.close();
                    this.showError(data);
                  }
                });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteCurrentPool() {
      return this.$store.dispatch('moduleStack/deletePool', this.tariff.id).catch(e => e);
    },
    async deleteCurrentListener() {
      return await this.$store
        .dispatch('moduleStack/deleteListener', this.tariff.listeners[0].id)
        .then(async () => {})
        .catch(e => e)
        .finally(() => {});
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Вы уверены, что хотите удалить правило:",
    "prolong": {
      "confirm" : "Подтвердить",
      "order": "Отмена",
      "title": "Смена конфигурации"
    },
    "actions": {
      "edit": "Переименовать правило",
      "false": "Выключить",
      "true": "Включить",
      "ssl": "Заменить SSL",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "dhcp": "Включить|Выключить DHCP",
      "delete": "Удалить правило",
      "descr": "Изменить описание",
      "description": "Описание правила:"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "description": {
      "name": "Описание правила",
      "text" : "Текстовое описание",
      "title" : "Режим восстановления",
      "true": "Подтвердите включение правила <b>%{name}</b>.",
      "false": "Подтвердите выключение правила <b>%{name}</b>.",
      "confirm": "Подтвердить",
      "rescue": "Запустить"
    },
    "sure": {
      "confirm": "Подтвердить"
    },
    "modal": {
      "sure": {
        "text": "Удалить правило <b>%{msg}</b> (id: %{pool})?",
        "confirm": "Подтвердить",
        "action": "Вы собираетесь <b>%{msg}</b> правило. <br /><br /> Вы уверены?",
        "close": "Закрыть",
        "true": "включено",
        "false": "выключено",
        "delete": "Правило успешно удалено.",
        "success": "Правило успешно %{msg}.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
